import { createAsyncThunk } from '@reduxjs/toolkit';
import { sortArray } from 'utils/arrayFunctions';
import { instance } from 'api/api.config';
import { REPORTS_MAKE_CALLS } from 'constans/endpoints';
import { requestHandler } from 'pages/Reports/ReportsFunctions/MakeDataForRequest';
import { optionsCreatorVEL } from 'utils/optionsCreator';
import { convertSavedReportFromHash } from 'pages/Reports/ReportsFunctions/convertData';
import { searchSlice } from 'store';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { SimpleObject } from 'pages/Reports/types/ReportsTypes';
import { RootState } from '../store';
import { reportsSlice } from '.';
import {
	ExportReportPayload,
	PatchReportPayload,
	GetTagValuesPayload,
	GetTagValuesParamPayload,
} from './namespaces/payloads';
import { ReportParametersType } from './reports.types';

export const getAllReports = createAsyncThunk('reports/getAllReports', async (_, thunkAPI) => {
	const response = await instance.get('reports');
	thunkAPI.dispatch(reportsSlice.actions.setReports(response.data));
	return response.data;
});

export const saveReport = createAsyncThunk<string>('reports/saveReport', async (_, thunkAPI) => {
	try {
		// @ts-ignore;
		const state: RootState = thunkAPI.getState();
		const requestData = requestHandler(
			state.search.reports.activeCriterias,
			state.reports.activeReport,
			state.reports.additionalRows,
		);

		const response = await instance.post('reports', requestData);
		thunkAPI.dispatch(reportsSlice.actions.setIsSavedReport(true));
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const saveReportSimple = createAsyncThunk(
	'reports/saveReportSimple',
	async (payload: ReportParametersType, thunkAPI) => {
		try {
			const response = await instance.post('reports', payload);
			return response.data;
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	},
);

export const getReport = createAsyncThunk('reports/getReport', async (payload: string, thunkAPI) => {
	try {
		const response = await instance.get(`reports/${payload}`);

		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getReportByHash = createAsyncThunk(
	'reports/getReportByHash',
	async (payload: { hash: string; selectTitlesOptions: SimpleObject }, thunkAPI) => {
		try {
			const response = await instance.get(`reports/parameters/${payload.hash}`);

			// @ts-ignore;
			const state: RootState = thunkAPI.getState();
			const {
				report: convertedReport,
				additionalRows,
				additionalColumns,
				callSearch,
			} = convertSavedReportFromHash(
				response.data,
				payload.selectTitlesOptions,
				state.search.allCriterias,
				criteriaWithComplexValues,
			);

			thunkAPI.dispatch(reportsSlice.actions.setActiveReportByHash(convertedReport));
			thunkAPI.dispatch(reportsSlice.actions.setAllAdditionalColumns(additionalColumns));
			thunkAPI.dispatch(reportsSlice.actions.setAllAdditionalRows(additionalRows));

			thunkAPI.dispatch(
				searchSlice.actions.setActiveCriterias({
					page: 'reports',
					data: callSearch,
				}),
			);

			return response.data;
		} catch (error) {
			thunkAPI.rejectWithValue(error);
		}
	},
);

export const makeReport = createAsyncThunk('make_report/calls/makeReport', async (_, thunkAPI) => {
	// @ts-ignore;
	const state: RootState = thunkAPI.getState();
	const requestData = requestHandler(
		state.search.reports.activeCriterias,
		state.reports.activeReport,
		state.reports.additionalRows,
	);

	const { data } = await instance.post(REPORTS_MAKE_CALLS, requestData);
	thunkAPI.dispatch(reportsSlice.actions.setCallReport(data));
	thunkAPI.dispatch(reportsSlice.actions.setReportCollapsed(true));

	return data;
});

export const deleteReport = createAsyncThunk('reports/deleteReport', async (payload: string, thunkAPI) => {
	try {
		const { data } = await instance.delete(`reports/${payload}`);
		thunkAPI.dispatch(getAllReports());
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const updateReport = createAsyncThunk('reports/updateReport', async (payload: string, thunkAPI) => {
	try {
		// @ts-ignore;
		const state: RootState = thunkAPI.getState();
		const requestData = requestHandler(
			state.search.reports.activeCriterias,
			state.reports.activeReport,
			state.reports.additionalRows,
		);

		const { data } = await instance.put(`reports/${payload}`, requestData);
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const patchReport = createAsyncThunk('reports/patchReport', async (payload: PatchReportPayload, thunkAPI) => {
	try {
		const { data } = await instance.patch(`reports/${payload.reportId}`, payload.data);
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

const makeFileExcel = (response: any) => {
	// получение названия файла excel
	const headerContent = response.headers['content-disposition'];
	let filename = '';

	if (headerContent && headerContent.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"])'*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(headerContent);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	} else {
		filename = 'report.xlsx';
	}
	// создание файла excel
	const url = window.URL.createObjectURL(
		new Blob([response.data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
		}),
	);
	const link = document.createElement('a');
	link.href = url;
	const filenameDecode: string = decodeURIComponent(filename);
	link.setAttribute('download', `${filenameDecode}`);
	document.body.appendChild(link);
	link.click();
};

export const exportReport = createAsyncThunk('reports/exportReport', async (payload: ExportReportPayload, thunkAPI) => {
	try {
		// @ts-ignore;
		const state: RootState = thunkAPI.getState();
		let requestData;
		if (payload.reportBody) {
			requestData = payload.reportBody;
		} else {
			requestData = requestHandler(
				state.search.reports.activeCriterias,
				state.reports.activeReport,
				state.reports.additionalRows,
				payload.reportId,
			);
		}

		const urlPath = payload.reportHash ? `report_hash=${payload.reportHash}` : `report_id=${payload.reportId}`;
		const requestParameters = `/${REPORTS_MAKE_CALLS}export/?export_format=excel&${urlPath}`;

		const response = await instance.post(requestParameters, requestData, { responseType: 'blob' });

		makeFileExcel(response);
		return response;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getSelectors = createAsyncThunk('reports/getSelectors', async (_, thunkAPI) => {
	try {
		// @ts-ignore;
		const state: RootState = thunkAPI.getState();
		const response = await instance.get('reports/selectors');
		thunkAPI.dispatch(
			reportsSlice.actions.getSelectorsValues({ selectors: response.data, tags: state.reports.tagNames }),
		);
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getTagNames = createAsyncThunk('user/getTagNames', async (_, thunkAPI) => {
	try {
		const response = await instance.get('tag_names');
		thunkAPI.dispatch(reportsSlice.actions.setTagNames(response.data));
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getTagNamesWithValues = createAsyncThunk('user/getTagNamesWithValues', async (_, thunkAPI) => {
	try {
		const response = await instance.get('tag_names?only_with_values=true');
		thunkAPI.dispatch(reportsSlice.actions.setTagNamesWithValues(response.data));
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getNumTagNames = createAsyncThunk('user/getNumTagNames', async (_, thunkAPI) => {
	try {
		const response = await instance.get('tag_names?only_numeric=true');
		thunkAPI.dispatch(reportsSlice.actions.setNumTagsNames(response.data));
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getTagValues = createAsyncThunk('user/getTagValues', async (payload: GetTagValuesPayload, thunkAPI) => {
	try {
		const response = await instance.get(`tag_values?name=${encodeURIComponent(payload.value)}`);
		if (payload.view === 'column') {
			thunkAPI.dispatch(reportsSlice.actions.setTagValuesColumn(response.data));
		} else {
			thunkAPI.dispatch(reportsSlice.actions.setTagValuesRow(response.data));
		}
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getTagValuesAlone = createAsyncThunk('user/getTagValuesAlone', async (payload: string, thunkAPI) => {
	try {
		const response = await instance.get(`tag_values?name=${encodeURIComponent(payload)}`);
		thunkAPI.dispatch(reportsSlice.actions.setTagValuesColumnAdd(response.data));

		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const getTagValuesParam = createAsyncThunk(
	'user/getTagValuesParam',
	async (payload: GetTagValuesParamPayload, thunkAPI) => {
		try {
			const response = await instance.get(`tag_values?name=${encodeURIComponent(payload.value)}`);
			if (payload.field === 'column') {
				thunkAPI.dispatch(
					reportsSlice.actions.setParamTagValues({
						arrayIndex: payload.arrayIndex,
						value: sortArray(response.data),
					}),
				);
			} else {
				thunkAPI.dispatch(
					reportsSlice.actions.setAdditionalRowValue({
						index: payload.arrayIndex,
						data: optionsCreatorVEL(sortArray(response.data)),
						option: 'tagValuesRow',
					}),
				);
			}
			return response.data;
		} catch (error: any) {
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	},
);
