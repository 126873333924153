import { FC, useState, ChangeEvent, useEffect } from 'react';
import { AccessRights } from 'store/user/user.types';
import { PlusIcon } from 'components/Icons/PlusIcon';
import { ManageIcon } from 'components/Icons/ManageIcon';
import { SavedReportType } from 'store/reports/reports.types';
import { LangType } from 'store/lang/lang.slice';
import { translate } from 'localizations';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser, useCurrentUserRole } from 'hooks';
import useZeroParameters from 'pages/Reports/hooks/useZeroParameters';
import { pathCreateReport, pathReport, pathReports } from 'pages/Reports/const/const';
import { SearchInput, DropdownWithChildren, LayoutWithPlaceholder } from 'components/common';
import useMakeReportById from 'pages/Reports/hooks/useMakeReportById';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { reportsSlice } from 'store';
import useMakeReport from 'pages/Reports/hooks/useMakeReport';

import { useStyles } from './CustomSelectWithSearch.jss';

interface CustomSelectWithSearchProps {
	accessRights: AccessRights | null;
	allReports: SavedReportType[];
	language: LangType | string;
	isOpenSelect: boolean;
	dropdownCloseFunction: () => void;
}

const CustomSelectWithSearch: FC<CustomSelectWithSearchProps> = ({
	accessRights,
	allReports,
	language,
	isOpenSelect,
	dropdownCloseFunction,
}) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const currentUser = useCurrentUser();
	const userId = currentUser ? currentUser.id : '_';

	const { loggedUser } = useAppSelector((state) => state.user);

	const { resetAllForNewReport } = useZeroParameters();

	const [searchValue, setSearchValue] = useState('');
	const [filteredReports, setFilteredReports] = useState(allReports);
	const filterReports = (value: string, allReportsList: SavedReportType[]) => {
		if (value) {
			setFilteredReports(
				allReportsList.filter((report) => report.name.toLowerCase().includes(value.toLowerCase())),
			);
		} else {
			setFilteredReports(allReports);
		}
	};

	const handleSearchValue = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
		filterReports(e.target.value, allReports);
	};

	const handleClick = () => {
		dropdownCloseFunction();
		resetAllForNewReport();
	};

	useEffect(() => {
		if (allReports.length) {
			setFilteredReports(allReports);
		}
	}, [allReports]);

	const currentUserRole = useCurrentUserRole();
	const canSeePageContent = currentUserRole === 'user';
	const hasAccessToReport = accessRights && accessRights.report;
	const hasAccessToReportManage = accessRights && accessRights.report_manage;

	const adminMessage = translate('reportUser', language);
	const hasAccessMessage = translate('reportNotAvailable', language);

	const placeholderMessage = (!canSeePageContent && adminMessage) || (!hasAccessToReport && hasAccessMessage) || '';

	const { getSavedReportById } = useMakeReportById();
	const { handleCloseWebsocket } = useMakeReport();

	const handleReportSelect = (filteredReport: SavedReportType) => {
		handleCloseWebsocket();
		resetAllForNewReport();
		getSavedReportById({ value: filteredReport.id, label: filteredReport.name });
		dispatch(reportsSlice.actions.setCurrentSavedReport({ value: filteredReport.id, label: filteredReport.name }));
		history.push(`/${language}/${userId}/${pathReport}/${filteredReport.id}`);
		dispatch(reportsSlice.actions.setReportCollapsed(false));

		dropdownCloseFunction();
	};

	return (
		<DropdownWithChildren dropdown={isOpenSelect} position="left" customWidth>
			<div className={classes.select}>
				<LayoutWithPlaceholder
					showContent={Boolean(canSeePageContent && hasAccessToReport)}
					placeholder={placeholderMessage}
					className={classes.messageNotAvail}
				>
					<>
						<div className={classes.selectTop}>
							<Link to={`/${language}/${userId}/${pathCreateReport}`}>
								<div className={classes.titleFlex} onClick={handleClick}>
									<PlusIcon fill="000" />
									<div className={classes.titlePd}>{translate('makeNewReport', language)}</div>
								</div>
							</Link>

							{hasAccessToReportManage && (
								<Link to={`/${language}/${userId}/${pathReports}`}>
									<div className={classes.titleFlex} onClick={handleClick}>
										<ManageIcon />
										<div className={classes.titlePd}>{translate('manageListReport', language)}</div>
									</div>
								</Link>
							)}
						</div>
						<div className={classes.selectBottom}>
							<div className={classes.selectGrayTitle}>
								{translate('savedReports', language)}&nbsp;
								{`(${allReports.length})`}
							</div>
							<SearchInput
								value={searchValue}
								placeholder={translate('searchTag', language)}
								handleChange={handleSearchValue}
								classNameRoot={classes.searchInput}
							/>
							<div className={classes.reportsLists}>
								{allReports.length ? (
									<div>
										{filteredReports.length ? (
											filteredReports.map((report) => (
												<div
													className={classes.reportItem}
													key={report.id}
													onClick={() => handleReportSelect(report)}
												>
													<Link to={`/${language}/${userId}/${pathReport}/${report.id}`}>
														{report.name}
													</Link>
												</div>
											))
										) : (
											<div>{translate('noOptionsMessage', language)}</div>
										)}
									</div>
								) : (
									<div>{translate('dontHaveReports', language)}</div>
								)}
							</div>
						</div>
					</>
				</LayoutWithPlaceholder>
			</div>
		</DropdownWithChildren>
	);
};

export default CustomSelectWithSearch;
