import { FC, useEffect, useRef, useState } from 'react';
import TooltipCustom from 'components/common/Tooltip/Tooltip';
import cls from './styles.module.scss';
import CopyToClipboardIcon from '../icons/notifyIcons/CopyToClipboardIcon/CopyToClipboardIcon';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';
import { translate } from 'localizations';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';

interface CopyToClipboardProps {
	text: string;
	botKey: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, botKey }) => {
	const { lang } = useParams<RouteParams>();
	const [isCopied, setIsCopied] = useState(false);

	const timeoutRef = useRef<number | null>(null);

	const handleCopyClick = async () => {
		try {
			await navigator?.clipboard?.writeText(`${text} ${botKey}`);
			setIsCopied(true);
			timeoutRef.current = window.setTimeout(() => {
				setIsCopied(false);
				timeoutRef.current = null; // Сбрасываем реф после выполнения
			}, 2000);
		} catch (err) {
			console.error('Failed to copy text: ', err);
		}
	};

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<div className={cls.root}>
			<span className={cls.text}>{`${text} ${botKey}`}</span>
			<TooltipCustom title={isCopied ? translate('cloned', lang) : translate('copyToBuffer', lang)}>
				<UniversalButton
					text=""
					icon={<CopyToClipboardIcon />}
					handleClick={handleCopyClick}
					buttonStyle="plain"
				/>
			</TooltipCustom>
		</div>
	);
};

export default CopyToClipboard;
