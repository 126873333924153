import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { sortArray } from 'utils/arrayFunctions';
import {
	CallInfoType,
	CallSttType,
	CallTranslatedText,
	CallType,
	MetaInfoType,
	ResponseBaseCallsDataType,
	SortType,
	TranslatedComments,
} from './calls.types';
import initialState from './initial';
import { SetFilterCallsHashPayload, SetSearchCallsHashPayload } from './namespaces/payloads';

const callsSlice = createSlice({
	name: 'calls',
	initialState,
	reducers: {
		setLoadingCalls(state, action: PayloadAction<boolean>) {
			state.isCallsLoading = action.payload;
		},
		// actions on calls
		setBaseCallsData(state, action: PayloadAction<ResponseBaseCallsDataType>) {
			state.callsData = action.payload;
		},

		setCalls(state, action: PayloadAction<(CallInfoType | null)[] | false | null>) {
			state.callsInfo = action.payload;
		},

		setCurrentCall(state, action: PayloadAction<CallType | null | false>) {
			state.currentCall = action.payload;
		},

		setCallsInfo(state, action: PayloadAction<CallInfoType[]>) {
			state.callsInfo = action.payload;
		},

		replaceCallInfoInArray(state, action: PayloadAction<CallInfoType>) {
			if (state.callsInfo) {
				const currentSelectedCalls = [...current(state.callsInfo)];
				const callInfo = currentSelectedCalls.find(
					(callInfoItem) => callInfoItem && callInfoItem.id === action.payload.id,
				);
				let index = -1;
				if (callInfo) {
					index = currentSelectedCalls.indexOf(callInfo);
				}
				currentSelectedCalls[index] = action.payload;
				state.callsInfo = currentSelectedCalls;
			}
		},

		setInfo(state, action: PayloadAction<CallInfoType>) {
			if (state.currentCall) {
				state.currentCall.info = action.payload;
			}
		},

		updateCall(state, action: PayloadAction<CallInfoType>) {
			const call = action.payload;
			const callId = call.id;
			if (state.callsInfo) {
				const candidateIndex = state.callsInfo.findIndex((x) => x?.id === callId);
				state.callsInfo[candidateIndex] = call;
			}
		},

		setStt(state, action: PayloadAction<CallSttType | null | false>) {
			if (state.currentCall) {
				state.currentCall.stt = action.payload;
			}
		},

		setToken(state, action: PayloadAction<string>) {
			if (state.currentCall) {
				state.currentCall.token = action.payload;
			}
		},

		setCallTranslated(state, action: PayloadAction<CallTranslatedText[] | null>) {
			state.callTranslated = action.payload;
		},

		setCallLoading(state, action: PayloadAction<boolean>) {
			state.isCallLoading = action.payload;
		},

		setSearchCallsHash(state, action: PayloadAction<SetSearchCallsHashPayload>) {
			state.searchHash = action.payload;
		},

		setFilterCallsHash(state, action: PayloadAction<SetFilterCallsHashPayload>) {
			state.filterHash = action.payload;
		},
		// expanded
		setExpanded(state, action: PayloadAction<{ id: string | null }>) {
			state.expandedId = action.payload.id;
		},

		// sorting
		setSort(state, action: PayloadAction<SortType>) {
			state.sort = action.payload;
		},

		callsReset: () => initialState,

		deleteCall(state, action: PayloadAction<{ id: string }>) {
			if (state.callsInfo) {
				const currentCalls = [...current(state.callsInfo)];
				if (currentCalls) {
					const call: CallInfoType | null | undefined = currentCalls.find((callInfo) => {
						if (callInfo) {
							return callInfo.id === action.payload.id;
						}
						return undefined;
					});
					let callIndex = -1;
					if (call) {
						callIndex = currentCalls.indexOf(call);
					}
					if (callIndex !== -1) {
						currentCalls.splice(callIndex, 1);
					}
					state.currentCall = null;
					state.callsInfo = currentCalls;
				}
			}
		},

		setFragmentExpanded: (state, action: PayloadAction<{ callId: string; isExpanded: boolean }>) => {
			const { callId, isExpanded } = action.payload;
			state.expandedFragments[callId] = isExpanded;
		},
		setCallTagExpanded: (state, action: PayloadAction<{ callId: string; isExpanded: boolean }>) => {
			const { callId, isExpanded } = action.payload;
			state.expandedCallTags[callId] = isExpanded;
		},
		setDealTagExpanded: (state, action: PayloadAction<{ callId: string; isExpanded: boolean }>) => {
			const { callId, isExpanded } = action.payload;
			state.expandedDealTags[callId] = isExpanded;
		},
		setMetaInfo: (state, action: PayloadAction<MetaInfoType[]>) => {
			state.metaInfo = action.payload;
		},
		setMetaInfoIsLoading: (state, action: PayloadAction<boolean>) => {
			state.metaInfoIsLoading = action.payload;
		},
		setTagNames(state, action: PayloadAction<[]>) {
			state.tagNames = sortArray(action.payload);
		},
		setLangForTranslate(state, action: PayloadAction<string | null>) {
			state.langForTranslate = action.payload;
		},
		setTranslatedComments(state, action: PayloadAction<TranslatedComments[]>) {
			state.translatedComments = action.payload;
		},
	},
});

export const callsActions = callsSlice.actions;

export default callsSlice.reducer;
