import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { GPTCallComment as GPTComment } from 'store/calls/calls.types';
import { CircularPreloader, LabelSelect } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { translate } from 'localizations';
import { Ellipsis } from 'components/Icons/Ellipsis';
import { gptCommentAPI } from 'services/gptCommentService';
import CommentIcon from 'shared/ui/icons/general/CommentIcon/CommentIcon';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import CommentEditMode from './CommentEditMode';
import CommentHeader from './CommentHeader';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { InfoCircle, SelectWithIcon } from 'shared/ui';
import { callsActions } from 'store/calls';
import { ModalWindow, UniversalButton } from 'shared/ui';

import s from './styles.module.scss';
import { getAllLanguages } from 'store/lang/lang.slice';

interface GPTCallCommentProps extends GPTComment {
	callId: string;
	className?: string;
	withOptions?: boolean;
	index?: number;
	comments: GPTComment[];
}

const GPTCallComment: FC<GPTCallCommentProps> = ({
	callId,
	call,
	id,
	creatorName,
	editorName,
	editTime,
	addTime,
	title,
	message,
	className,
	withOptions = false,
	isPrivate,
	index,
	comments,
}) => {
	const { lang } = useParams<RouteParams>();
	const dispatch = useAppDispatch();

	const languagesList = useAppSelector((state) => state.lang.allLanguages);

	const [deleteGptCommentById] = gptCommentAPI.useDeleteGptCommentByIdMutation();
	const [updateGptCommentById] = gptCommentAPI.useUpdateGptCommentByIdMutation();

	const translatedComments = useAppSelector((state) => state.calls.translatedComments);
	const [selectedLang, setSelectedLang] = useState<string | null>(null);

	useEffect(() => {
		// Находим элемент в translatedComments, где callId совпадает с call текущего комментария
		const matchedTranslation = translatedComments.find((tc) => tc.callId.toLowerCase() === call?.toLowerCase());

		// Если такой элемент найден, устанавливаем язык из него в selectedLang
		if (matchedTranslation) {
			setSelectedLang(matchedTranslation.langValue);
		} else {
			setSelectedLang(null);
		}
	}, [translatedComments, call]);

	const [matchedCommentIds, setMatchedCommentIds] = useState<string[]>([]);

	useEffect(() => {
		setMatchedCommentIds(
			translatedComments.flatMap((tc) =>
				comments
					.filter((comment) => comment?.call?.toLowerCase() === tc?.callId.toLowerCase())
					.map((comment) => comment?.id),
			),
		);
	}, [translatedComments]);

	const {
		data: translatedQuery, // данные запроса
		isLoading, // индикатор загрузки
		error, // ошибка
		isError, // индикатор ошибки
		refetch, // функция для повторного запроса
	} = gptCommentAPI.useGetCommentTranslationQuery(
		{ comment_id: id, language: selectedLang },
		{ skip: !selectedLang || !matchedCommentIds.includes(id) },
	);

	useEffect(() => {
		if (selectedLang && selectedLang !== 'original' && matchedCommentIds.includes(id)) {
			refetch();
		}
	}, [selectedLang, matchedCommentIds, id, refetch]);

	const languagesOptions = [{ value: 'original', label: `${translate('original', lang)}` }, ...languagesList];

	const getTranslateHandle = (callIdForTranslate: string, languageValue: { value: string; label: string }) => {
		dispatch(callsActions.setTranslatedComments([{ callId: callIdForTranslate, langValue: languageValue.value }]));
	};

	const [isEmptyData, setIsEmptyData] = useState(false);

	useEffect(() => {
		setIsEmptyData(!isError && !translatedQuery);
	}, [translatedQuery]);

	const { updateCall } = useActionsFunctions(undefined, callId);

	const options: OptionType[] = [
		{ value: 'edit', label: translate('edit', lang) },
		{ value: 'delete', label: translate('deleteComment', lang) },
	];

	const [editMode, setEditMode] = useState(false);
	const [editTitle, setEditTitle] = useState(title);
	const [editMessage, setEditMessage] = useState(message);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOptions = (option: OptionType) => {
		if (option.value === 'delete') {
			setIsModalOpen(true);
		}
		if (option.value === 'edit') {
			setEditMode(!editMode);
		}
	};

	const confirmDelete = async () => {
		await deleteGptCommentById(id);
		await updateCall(callId);
		setIsModalOpen(false);
	};

	const cancelDelete = () => {
		setIsModalOpen(false);
	};

	const submitChanges = async () => {
		await updateGptCommentById({
			id,
			body: {
				title: editTitle,
				message: editMessage,
			},
		});
		setEditMode(!editMode);
		await updateCall(callId);
	};

	return (
		<article className={classNames(s.root, className)}>
			<span className={s.icon}>
				<CommentIcon />
			</span>
			<div className={s.content}>
				<div className={s.headerWrapper}>
					<CommentHeader
						editorName={editorName}
						creatorName={creatorName}
						editTime={editTime}
						addTime={addTime}
						isPrivate={isPrivate}
					/>
					{!withOptions && index === 0 && (
						<>
							{selectedLang && translatedQuery?.translatedMessage && (
								<div className={s.header}>{translate('translationOfComment', lang)}</div>
							)}

							<SelectWithIcon
								buttonText={
									languagesList.filter((lang) => lang.value === selectedLang)[0]?.label ||
									translate('translate', lang)
								}
								options={languagesOptions}
								onOptionSelect={(option) => getTranslateHandle(callId, option)}
								className={s.topTranslateButton}
							/>
						</>
					)}
				</div>

				{withOptions && (
					<LabelSelect
						className={s.optionsSelect}
						notPlusMinus
						isSingle
						closeMenuSelect
						value={[]}
						menuWidth="250px"
						options={options}
						valueHandler={(value: OptionType) => handleOptions(value)}
						customLabel={
							<IconButton size="small">
								<Ellipsis />
							</IconButton>
						}
					/>
				)}

				{editMode ? (
					<CommentEditMode
						submitChanges={submitChanges}
						setEditMode={setEditMode}
						title={title}
						message={message}
						editTitle={editTitle}
						setEditTitle={setEditTitle}
						setEditMessage={setEditMessage}
						editMessage={editMessage}
					/>
				) : (
					<>
						{isLoading ? (
							<div className={s.loader}>
								<CircularPreloader />
							</div>
						) : selectedLang && selectedLang !== 'original' ? (
							<div className={s.whithTranslateWrapper}>
								<div className={s.original}>
									{editTitle && <h3 className={s.title}>{editTitle}</h3>}
									<div className={s.message}>{editMessage}</div>
								</div>
								<div className={s.translated}>
									{isError ? (
										<div className={s.errorMessage}>
											<InfoCircle className={s.infoCircle} fill="#F5222D" />
											<div className={s.errorText}>
												{translate('commentTranslateError', lang)}
											</div>
										</div>
									) : isEmptyData ? (
										<div className={s.emptyMessage}>
											<InfoCircle className={s.infoCircle} fill="#722ED1" />
											<div className={s.emptyText}>
												{translate('commentTranslateEmpty', lang)}
											</div>
										</div>
									) : (
										editTitle && <h3 className={s.title}>{translatedQuery?.translatedTitle}</h3>
									)}
									<div className={s.message}>{translatedQuery?.translatedMessage}</div>
								</div>
							</div>
						) : (
							<>
								{editTitle && <h3 className={s.title}>{editTitle}</h3>}
								<div className={s.message}>{editMessage}</div>
							</>
						)}
					</>
				)}
			</div>
			<ModalWindow
				handleMWClose={cancelDelete}
				isMWOpen={isModalOpen}
				text={translate('confirmDeleteMessage', lang)}
			>
				<div className={s.buttonsBlock}>
					<UniversalButton
						buttonStyle="filled"
						className={s.cancelButton}
						text={translate('cancel', lang)}
						handleClick={cancelDelete}
					/>
					<UniversalButton
						buttonStyle="filled"
						text={translate('delete', lang)}
						handleClick={confirmDelete}
					/>
				</div>
			</ModalWindow>
		</article>
	);
};

export default GPTCallComment;
