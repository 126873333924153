import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { SttValuesForRequest } from 'widgets/STT/namespaces';
import { getTaskStatus, tasksSlice } from 'store/tasks/tasks.slice';
import { callAction, callApplyGpt, getCallInfo } from 'store/calls/actions';
import { callsAction, getCallStt } from 'store/calls/calls.slice';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { callsActions } from 'store/calls';
import { checkListsActions } from 'store/checkLists';
import { getCheckListsByCallId } from 'store/checkLists/actions';

import { selectCalls, selectCallsInfo } from 'store/calls/selectors';
import { CallInfoType, ResponseBaseCallsDataType } from 'store/calls/calls.types';
import { exportsActions } from 'pages/Calls/components/CallsHeader/const';
import { useParametersForAction } from './useParametersForAction';

export const useActionsFunctions = (filterHash: string | undefined | null, singleCallId: string | null = null) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { setParameters, setParametersSingleCall } = useParametersForAction();
	const { language } = useAppSelector((state: RootState) => state.lang);
	const callsData = useAppSelector(selectCalls);
	const callsInfo = useAppSelector(selectCallsInfo);
	const mode = useAppSelector((state) => state.search.calls.mode);
	const search_filter_hash = useAppSelector((state) => state.search.reports.search_filter_hash);

	const setWarningSnackbar = () => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				text: translate('actionIsNotAvailable', language),
				type: 'warning',
				time: 2000,
			}),
		);
	};
	const setActionStartSnackbar = () => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'info',
				text: translate('actionStart', language),
				time: 2000,
			}),
		);
	};
	const setSuccessSnackbar = (keyTranslate: string) => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'success',
				text: translate(keyTranslate, language),
				time: 2000,
			}),
		);
	};

	const updateCall = async (callId: string) => {
		dispatch(callsActions.setCallLoading(true));
		dispatch(checkListsActions.setCallsCheckListsLoading(true));
		const newCallInfoData = await dispatch(getCallInfo({ id: callId })); // @ts-ignore
		const newCallInfo = newCallInfoData.payload;
		dispatch(callsActions.replaceCallInfoInArray(newCallInfo));
		dispatch(callsActions.setInfo(newCallInfo));
		dispatch(getCallStt({ id: callId }));
		dispatch(callsActions.setCallLoading(false));
		dispatch(getCheckListsByCallId(callId));
	};

	const resetCallsHash = () => {
		// очищаем хэш поиска звонков, тк список звонков может меняться после каких-то действий
		dispatch(callsActions.setSearchCallsHash(undefined));
	};

	const setRealtimeTask = async (taskId: string, action: string | null = null) => {
		// нам не надо перезапрашивать звонки у вызова для одного звонка (кроме удаления)
		const taskData = await dispatch(getTaskStatus(taskId)); // @ts-ignore
		const task = taskData.payload;
		if (action) {
			dispatch(tasksSlice.actions.addRealtimeTask({ ...task, action, startProgress: false }));
		} else {
			dispatch(tasksSlice.actions.addRealtimeTask({ ...task, startProgress: false }));
		}
		dispatch(tasksSlice.actions.setNewTaskMessage(true));
	};

	const deleteOneCall = (callsArray: (CallInfoType | null)[] | null | false, callId: string | null) => {
		if (callsArray && callsArray.length > 0) {
			const filteredCalls = callsArray.filter((call) => call?.id !== callId);
			return filteredCalls;
		}
		return callsArray;
	};

	const deleteOneId = (callsIds: string[], callId: string | null) =>
		callsIds.filter((id) => id.toLowerCase() !== callId);

	const decreaseTotalAndFoundCalls = (callsDataInfo: ResponseBaseCallsDataType | null) => {
		if (callsDataInfo) {
			let found = callsDataInfo?.found;
			let total = callsDataInfo?.total;
			const filteredCalls = deleteOneCall(callsInfo, singleCallId);
			const filteredIds = deleteOneId(callsDataInfo.call_ids, singleCallId);

			const newCallsDataInfo = {
				...callsDataInfo,
				found: --found,
				total: --total,
				calls: filteredCalls,
				call_ids: filteredIds,
			};
			return newCallsDataInfo;
		}
		return callsDataInfo;
	};

	const callActionDeleteHandler = async (callActionData: any) => {
		const actionData = await dispatch(callAction(callActionData)); // @ts-ignore
		if (actionData.meta.requestStatus === 'fulfilled') {
			dispatch(callsActions.setCurrentCall(false));
			dispatch(callsActions.setExpanded({ id: null }));

			const filteredCalls = deleteOneCall(callsInfo, singleCallId);
			const decreasedNumbers = decreaseTotalAndFoundCalls(callsData);
			// @ts-ignore
			dispatch(callsActions.setBaseCallsData({ ...decreasedNumbers })); // @ts-ignore
			dispatch(callsActions.setCallsInfo(filteredCalls));

			resetCallsHash();
			setSuccessSnackbar('callDeleted');
		} else {
			setWarningSnackbar();
		}
	};

	const callActionHandler = async (callActionData: any) => {
		setActionStartSnackbar();
		let actionData;

		if (callActionData?.data?.action === 'apply_gpt') {
			actionData = await dispatch(callApplyGpt(callActionData));
		} else {
			actionData = await dispatch(callAction(callActionData));
		}

		// @ts-ignore
		const taskIdData: { task_id: string } | undefined = actionData?.payload;
		const taskId = taskIdData?.task_id; // @ts-ignore
		const successAction = actionData?.meta?.requestStatus === 'fulfilled';

		if (taskId) {
			// resetCallsHash();
			setRealtimeTask(taskId, callActionData?.data?.action);
		} else if (successAction) {
			// обновлять звонок во всех действиях, кроме "применить информирование"
			if (singleCallId && callActionData?.data?.action !== 'apply_notify_rules') {
				// resetCallsHash();
				updateCall(singleCallId);
			}
			setSuccessSnackbar('actionDone');
		} else {
			setWarningSnackbar();
		}
	};

	const callsActionHandler = async (callsActionData: any) => {
		setActionStartSnackbar();
		const actionData = await dispatch(callsAction(callsActionData)); // @ts-ignore
		const taskIdData: { task_id: string } | undefined = actionData.payload;
		const taskId = taskIdData?.task_id;
		if (taskId) {
			resetCallsHash();
			if (exportsActions.includes(callsActionData.action)) {
				setRealtimeTask(taskId, callsActionData.action);
			} else {
				setRealtimeTask(taskId);
			}
		} else {
			setWarningSnackbar();
		}
	};

	const onActionClick = async (action: string, columns?: string[], stt?: SttValuesForRequest) => {
		// действия для одного звонка
		if (singleCallId) {
			if (action === 'delete') {
				const dataIdForCallAction = { id: singleCallId, data: setParameters({ action }) };
				await callActionDeleteHandler(dataIdForCallAction);
				return;
			}

			const dataAndIdForCallAction = setParametersSingleCall({
				action,
				id: singleCallId,
				columns,
				stt,
			});

			await callActionHandler(dataAndIdForCallAction);
		} else {
			// действия для массива звонков
			let dataAndIdForCallsAction;
			if (mode === 'REPORTS') {
				dataAndIdForCallsAction = setParameters({
					action,
					callIds: Array.isArray(callsInfo)
						? callsInfo.filter((call): call is CallInfoType => !!call).map((call) => call.id) // Фильтруем null значения и собираем id
						: [],
					mode,
					columns,
					stt,
				});
			} else {
				dataAndIdForCallsAction = setParameters({ action, filterHash, columns, stt });
			}
			await callsActionHandler(dataAndIdForCallsAction);
		}
	};

	const onActionSTTClick = (values: SttValuesForRequest) => {
		onActionClick('stt', undefined, values);
	};

	return { onActionClick, onActionSTTClick, updateCall };
};
