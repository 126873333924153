import { CallInitialStateType } from './calls.types';
import { CALLS_LIMIT_ON_ON_PAGE } from './const';

const initialState: CallInitialStateType = {
	metaInfo: [],
	metaInfoIsLoading: false,

	callsData: null,

	callsLimitOnOnePage: CALLS_LIMIT_ON_ON_PAGE,

	callsInfo: null,
	currentCall: null,
	expandedId: null,

	sort: { sort: 'call_time', sortDesc: false },
	filterHash: undefined,
	searchHash: undefined,

	callTranslated: null,
	isCallLoading: false,
	isCallsLoading: false,
	expandedFragments: {},
	expandedCallTags: {},
	expandedDealTags: {},
	tagNames: [],
	langForTranslate: null,
	translatedComments: [],
	exportParams: { tags_with_values: [], tags_without_values: [], other_params: [] },
};

export default initialState;
