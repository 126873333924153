import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { Typography, Skeleton } from '@mui/material';
import { searchSlice } from 'store';
import { DisableMask, CriteriaTemplate, CriteriasList, Tooltip as TooltipCustom } from 'components/common';
import { translate } from 'localizations';
import { IconQuestion } from 'components/Icons/IconQuestion';
import { AccessRights } from 'store/user/user.types';
import { CriteriaBlockPage, CriteriasType, TemplateType } from 'store/search/search.types';
import { Page } from 'components/common/Criterias/CriteriasList';
import { optionsCreatorSimpleTitle } from 'utils/optionsCreator';
import { useCallSearchStyles } from '../../Search.jss';

interface IOwnProps {
	blockTitle?: string;
	simpleListWithTitles: boolean;
	accessRights: AccessRights | null;
	language: string;
	activeCriterias: CriteriasType[];
	allCriterias: CriteriasType[];
	onTemplateSelectValueChange: (event: any) => void;
	currentTemplate: TemplateType | null;
	allTemplates: TemplateType[];
	block?: CriteriaBlockPage;
	page: Page;
	onClearTemplateClick?: () => void;
	loadingCriteriasList?: boolean;

	// TODO: Igor - fix type
	convertedTemplate: any[];
}

const SearchFilters: React.FC<IOwnProps> = ({
	blockTitle,
	simpleListWithTitles,
	accessRights,
	language,
	allCriterias,
	activeCriterias,
	onTemplateSelectValueChange,
	convertedTemplate,
	currentTemplate,
	allTemplates,
	block,
	page,
	onClearTemplateClick,
	loadingCriteriasList = false,
}) => {
	const dispatch = useAppDispatch();
	const classes = useCallSearchStyles();

	return (
		<div>
			<DisableMask
				disable={!(accessRights && (accessRights.search || accessRights.admin))}
				tooltip={translate('noAvailableSearchFilters', language)}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className={classes.searchTitle} style={{ width: '60%' }}>
						<div className={classes.searchTitleLeft}>
							<Typography className={classes.searchTitleTitle} variant="subtitle1">
								{blockTitle || translate('fragmentSearch', language)}
							</Typography>

							<TooltipCustom
								background="dark"
								title={translate('tooltipFragments', language)}
								placement="right"
								followCursor={false}
							>
								<div className={classes.questionWrapper}>
									<IconQuestion />
									<div className={classes.questionTitle}>{translate('howToSearch', language)}</div>
								</div>
							</TooltipCustom>

							{/* шаблоны */}
							{!simpleListWithTitles && (
								<CriteriaTemplate
									height="400px"
									name="templatesCalls"
									title={translate('savedSearchTemp', language)}
									handleValueChange={onTemplateSelectValueChange}
									options={convertedTemplate}
									currentTemplate={optionsCreatorSimpleTitle(currentTemplate)}
									allTemplates={allTemplates}
									onClearTemplateClick={onClearTemplateClick}
									dataTestTd="templatesCalls"
								/>
							)}
						</div>
					</div>
					{!simpleListWithTitles && (
						<div className={classes.searchTitle} style={{ width: '35%', paddingLeft: '40px' }}>
							<div className={classes.searchTitleLeft}>
								<Typography className={classes.searchTitleTitle} variant="subtitle1">
									{translate('callsParameters', language)}
								</Typography>

								<TooltipCustom
									background="dark"
									title={translate('tooltipParam', language)}
									placement="bottom"
									followCursor={false}
								>
									<div className={classes.questionWrapper}>
										<IconQuestion />
										<div className={classes.questionTitle}>{translate('whatIsIt', language)}</div>
									</div>
								</TooltipCustom>
							</div>
						</div>
					)}
				</div>
			</DisableMask>
			<div style={{ display: 'flex', width: '100%' }}>
				<div style={{ display: 'flex', alignItems: 'flex-start', minHeight: '50px', width: '100%' }}>
					<div style={{ width: '100%' }}>
						<DisableMask
							disable={!(accessRights && (accessRights.search || accessRights.admin))}
							tooltip={translate('noAvailableSearchFilters', language)}
						>
							{loadingCriteriasList ? (
								<>
									<Skeleton width={300} height={40} />
								</>
							) : (
								<CriteriasList
									simpleListWithTitles={simpleListWithTitles}
									page={page}
									allCriterias={allCriterias}
									activeCriterias={activeCriterias}
									block={block}
									onAddCondition={(data, pageName) => {
										dispatch(
											searchSlice.actions.setActiveCriteriaValues({
												page: pageName as CriteriaBlockPage,
												data,
											}),
										);
									}}
									onDeleteCondition={(data, pageName) =>
										dispatch(
											searchSlice.actions.removeComplexCriteria({
												page: pageName as CriteriaBlockPage,
												data,
											}),
										)
									}
								/>
							)}
						</DisableMask>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchFilters;
